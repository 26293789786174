
import { hookstate } from '@hookstate/core';
export const baseUrl = "https://www.cryptogems.info";
export const apiUrl = "https://data.cryptogems.info";
export const isApp = false;
const mountedd = hookstate(isApp ? true : false);
const dataloadd = hookstate(isApp ? true : false);
const themed = hookstate("dark");
const paged = hookstate('home');
const chaind = hookstate('all');
const widthd = hookstate(isApp ? 800 : 1366);
const adviewd = hookstate(isApp ? false : false);
const displayadd = hookstate(false);
const stickyd = hookstate(false);
const adtyped = hookstate('bm');
const favsd = hookstate({});
const walletd = hookstate({
    connected: false,
    init: false,
    address: '',
    timeStamp: '',
    sig: ''
});
const popStatusd = hookstate(false);
const popTyped = hookstate(0); // 0-ad blocked, 1=disclaimer, 2=share, 3=wallet, 4=search, 5=my swap
const tableLoad = hookstate(false);
const globaldata = hookstate([]);
const historyDatad = hookstate([]);
const gainerd = hookstate(true);
const sideaccord = hookstate(2);
const sidedatad = hookstate({ chain: [{ symbol: 'all', blockNumber: 0, coinUSD: 0 }], gainers: [], losers: [], newpair: [] });
const marketd = hookstate({ type: 'usd', updated: false });
const pageNod = hookstate(0);
const addressd = hookstate("");
const useraddd = hookstate("");
const swapsd = hookstate(0);
const tvwd = hookstate(undefined);

const viewd = hookstate({
    top: true,
    center: true,
    bottom: true,
    side: true
});

const alertd = hookstate({
    status: false,
    duration: null,
    type: "success",
    message: "",
});

export const seoList = {
    /* "advertise": {
        "key": "advertise",
        "title": "Manage Ads",
        "tabTitle": "Manage Ads",
        "description": "Advertise on cryptogems"
    }, */
    "bigswap": {
        "key": "bigswap",
        "title": "Big Swaps",
        "tabTitle": "Big Swaps",
        "description": "Big Swaps on cryptogems"
    },
    "bot": {
        "key": "bot",
        "title": "Telegram Price Bot",
        "tabTitle": "Telegram Price Bot",
        "description": "Telegram Price Bot on cryptogems"
    },
    "dex": {
        "key": "dex",
        "title": "DEX Pair List",
        "tabTitle": "DEX Pair List",
        "description": "DEX Pair List on cryptogems"
    },
    "dexlist": {
        "key": "dexlist",
        "title": "DEX List",
        "tabTitle": "DEX List",
        "description": "DEX List on cryptogems"
    },
    "home": {
        "key": "home",
        "title": "Token List",
        "tabTitle": "Token List | Blockchain (ALL)",
        "description": "Blockchain (ALL) Chain Top Token List, DEX List, and Pair List ranked by 24-hour USD Volume along with price, chart, and Market Capital data. CryptoGems is the best Token, DEX, and Pair Explorer with Trending tool."
    },
    "newpair": {
        "key": "newpair",
        "title": "New Pairs",
        "tabTitle": "New Pairs",
        "description": "New Pairs on cryptogems"
    },
    "pair": {
        "key": "pair",
        "title": "Pair Chart",
        "tabTitle": "Pair Chart",
        "description": "Pair Chart on cryptogems"
    },
    "pairlist": {
        "key": "pairlist",
        "title": "Pair List",
        "tabTitle": "Pair List",
        "description": "Advertise on cryptogems"
    },
    "partners": {
        "key": "partners",
        "title": "Partners List",
        "tabTitle": "Partners List",
        "description": "Partners with cryptogems"
    },
    "privacy": {
        "key": "privacy",
        "title": "Privacy Policy",
        "tabTitle": "Privacy Policy",
        "description": "Cryptogems Privacy Policy"
    },
    "terms": {
        "key": "terms",
        "title": "Terms and Conditions",
        "tabTitle": "Terms and Conditions",
        "description": "Cryptogems Terms & Conditions"
    },
    "token": {
        "key": "token",
        "title": "Token Pair List",
        "tabTitle": "Token Pair List",
        "description": "Token Pair List on cryptogems"
    },
    "topgainers": {
        "key": "topgainers",
        "title": "Gainers",
        "tabTitle": "Top Gainers",
        "description": "Top Gainers on cryptogems"
    }
}

const seod = hookstate(seoList)

export const networkList = {
    "all": {
        "id": 0,
        "cgid": "all",
        "name": "Blockchain",
        "symbol": "all",
        "wsymbol": "all",
        "path": "all",
        "image": "/sf/logo.svg",
        "add_link": "",
        "tx_link": "",
        "token_link": "",
        "explorer": "",
        "rpc": "",
        "basePair": [
        ],
        "router": [
        ]
    },
    "eth": {
        "id": 1,
        "cgid": "ethereum",
        "name": "ethereum",
        "symbol": "eth",
        "wsymbol": "weth",
        "path": "eth",
        "image": "/sf/logo/sWJ7NC.png",
        "add_link": "https://etherscan.io/address/",
        "tx_link": "https://etherscan.io/tx/",
        "token_link": "https://etherscan.io/token/",
        "explorer": "https://etherscan.io",
        "rpc": "https://rpc.ankr.com/eth",
        "basePair": [
            "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
            "0xdac17f958d2ee523a2206206994597c13d831ec7",
            "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "0x4fabb145d64652a948d72533023f6e7a623c7c53"
        ],
        "router": [
            "0x40dde6092a77ec2d00eb4fa14f0c5d92d835d673",
            "0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45",
            "0xf164fc0ec4e93095b804a4795bbe1e041497b92a",
            "0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f",
            "0x881d40237659c251811cec9c364ef91dc08d300c"
        ]
    },
    "bsc": {
        "id": 56,
        "cgid": "binance-smart-chain",
        "name": "binance",
        "symbol": "bnb",
        "wsymbol": "wbnb",
        "path": "bsc",
        "image": "/sf/logo/Seh7Tp.png",
        "add_link": "https://bscscan.com/address/",
        "tx_link": "https://bscscan.com/tx/",
        "token_link": "https://bscscan.com/token/",
        "explorer": "https://bscscan.com",
        "rpc": "https://bsc-dataseed.binance.org/",
        "basePair": [
            "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
            "0x55d398326f99059ff775485246999027b3197955",
            "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
            "0xe9e7cea3dedca5984780bafc599bd69add087d56"
        ],
        "router": [
            "0x10ed43c718714eb63d5aa57b78b54704e256024e",
            "0x2ad2c5314028897aecfcf37fd923c079beeb2c56",
            "0x2029f4d96e980ac4f5841ae38b17e3feb0d75e66",
            "0x05ff2b0db69458a0750badebc4f9e13add608c7f",
            "0x267babbe93b9e9499aca9938dd46922590428643"
        ]
    },
    "matic": {
        "id": 137,
        "cgid": "polygon-pos",
        "name": "polygon",
        "symbol": "matic",
        "wsymbol": "wmatic",
        "path": "matic",
        "image": "/sf/logo/BJw4pC.png",
        "add_link": "https://polygonscan.com/address/",
        "tx_link": "https://polygonscan.com/tx/",
        "token_link": "https://polygonscan.com/token/",
        "explorer": "https://polygonscan.com",
        "rpc": "https://rpc.ankr.com/polygon",
        "basePair": [
            "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
            "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
            "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
            "0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7"
        ],
        "router": [
            "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
            "0x1b02da8cb0d097eb8d57a175b88c7d8b47997506",
            "0x11111112542d85b3ef69ae05771c2dccff4faa26"
        ]
    },
    "ftm": {
        "id": 250,
        "cgid": "fantom",
        "name": "fantom",
        "symbol": "ftm",
        "wsymbol": "wftm",
        "path": "ftm",
        "image": "/sf/logo/WuuE7d.png",
        "add_link": "https://ftmscan.com/address/",
        "tx_link": "https://ftmscan.com/tx/",
        "token_link": "https://ftmscan.com/token/",
        "explorer": "https://ftmscan.com",
        "rpc": "https://rpcapi.fantom.network/",
        "basePair": [
            "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
            "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
            "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e"
        ],
        "router": [
            "0x67a937ea41cd05ec8c832a044afc0100f30aa4b5",
            "0xf491e7b69e4244ad4002bc14e878a34207e38c29"
        ]
    },
    "avax": {
        "id": 43114,
        "cgid": "avalanche",
        "name": "avalanche",
        "symbol": "avax",
        "wsymbol": "wavax",
        "path": "avax",
        "image": "/sf/logo/2luHxn.png",
        "add_link": "https://snowtrace.io/address/",
        "tx_link": "https://snowtrace.io/tx/",
        "token_link": "https://snowtrace.io/token/",
        "explorer": "https://snowtrace.io",
        "rpc": "https://rpc.ankr.com/avalanche",
        "basePair": [
            "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
            "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
            "0xc7198437980c041c805a1edcba50c1ce5db95118",
            "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
            "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
            "0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98",
            "0xd586e7f844cea2f87f50152665bcbc2c279d8d70"
        ],
        "router": [
            "0x44771c71250d303d32e638c1c7ca7d00135cd65f",
            "0x85995d5f8ee9645ca855e92de16fa62d26398060",
            "0x60ae616a2155ee3d9a68541ba4544862310933d4"
        ]
    },
    /* "glmr": {
        "id": 1284,
        "cgid": "moonbeam",
        "name": "moonbeam",
        "symbol": "glmr",
        "wsymbol": "wglmr",
        "path": "glmr",
        "image": "/sf/logo/MEyIoV.png",
        "add_link": "https://moonscan.io/address/",
        "tx_link": "https://moonscan.io/tx/",
        "token_link": "https://moonscan.io/token/",
        "explorer": "https://moonscan.io",
        "rpc": "https://rpc.ankr.com/moonbeam",
        "basePair": [
            "0xacc15dc74880c9944775448304b263d191c6077f",
            "0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73",
            "0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b",
            "0xa649325aa7c5093d12d6f98eb4378deae68ce23f"
        ],
        "router": [
            "0xd0a01ec574d1fc6652edf79cb2f880fd47d34ab1",
            "0x96b244391d98b62d19ae89b1a4dccf0fc56970c7",
            "0x70085a09d30d6f8c4ecf6ee10120d1847383bb57"
        ]
    }, */
}

const networkd = hookstate(networkList);


export {
    adtyped,
    mountedd,
    dataloadd,
    displayadd,
    globaldata,
    historyDatad,
    themed,
    favsd,
    paged,
    chaind,
    adviewd,
    stickyd,
    networkd,
    viewd,
    alertd,
    widthd,
    seod,
    sideaccord,
    sidedatad,
    marketd,
    pageNod,
    tableLoad,
    popStatusd,
    popTyped,
    gainerd,
    addressd,
    useraddd,
    swapsd,
    tvwd,
    walletd
}
