//import '@/styles/globals.css';
/* import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/material-icons'; */
import { Roboto } from 'next/font/google';

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

import { useRouter } from 'next/router';

//import { Analytics } from '@vercel/analytics/react';

import TagManager from 'react-gtm-module';
import Head from 'next/head';
import { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import { useHookstate } from "@hookstate/core";
import { popStatusd, adviewd, isApp, themed, widthd, favsd, walletd } from '@/libs/store';

export function reportWebVitals(metric) {
  if (process.env.NODE_ENV !== 'development') {
    console.log(metric)
  }
}


export default function App(props) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const themeMode = useHookstate(themed);
  const widths = useHookstate(widthd);
  const popStatus = useHookstate(popStatusd);
  const adviewable = useHookstate(adviewd);
  const favList = useHookstate(favsd);
  const wallet = useHookstate(walletd);


  let theme = createTheme({
    palette: {
      mode: themeMode.get(),
      primary: {
        main: themeMode.get() == "dark" ? '#44c3f9' : '#00579e',
      },
      background: {
        default: themeMode.get() == "dark" ? "#121212" : '#b9cbd9',//"#121212" : '#f3cdb8',//'#2a2e39' : '#e0e3eb',
        paper: themeMode.get() == "dark" ? "#121212" : "#F8F5FA", //"#f2f2f2", "e5e5e5", "#ffffff" f7f4f2 fafafa
      }
    },
    components: {
      //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
      MuiDataGrid: {
        styleOverrides: {
          cell: {
            outline: 'none !important',
          },
          columnHeader: {
            outline: 'none !important',
          },
        }
      }
    },
    typography: {
      fontFamily: roboto.style.fontFamily,
    },
  });

  theme = createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              width: "4px",
              height: '4px',
              border: 0,
              m: 0,
              p: 0,
              backgroundColor: theme.palette.background.default,// == "dark" ? "#2b2b2b" : "#ffffff",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#6b6b6b",
            },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: theme.palette.background.default,// == "dark" ? "#2b2b2b" : "#ffffff",
            },
          },
        },
      },
    },

  });

  useEffect(() => {

    const themeDetails = localStorage.getItem("theme");
    if (themeDetails) {
      themeMode.set(themeDetails);
    } else {
      localStorage.setItem("theme", themeMode.get());
    }

    const favDetails = localStorage.getItem("cgfav");
    if (favDetails) {
      favList.set(JSON.parse(favDetails));
    } else {
      localStorage.setItem("cgfav", JSON.stringify(favList.get()));
    }

    var walletDetails = localStorage.getItem("cgwallet");
    if (walletDetails) {
      walletDetails = JSON.parse(walletDetails);
      if (walletDetails && walletDetails.connected) {
        wallet.merge(walletDetails);
        //console.log("wallet", wallet.get());
      }
    }

    //mounted.set(true);
    //setInterval(() => {
    if (isApp) {

    } else {
      //checkAdBlocker();
    }
    //}, 5000);
    //console.log(views.get(), views.get({ noproxy: true }), views.get({ stealth: true }))
    handleResize();
    //pwa();

    window.addEventListener('resize', handleResize);
    window.addEventListener('storage', function (event) {
      //console.log(event);
      const themeDetails = localStorage.getItem("theme");
      if (themeDetails) {
        themeMode.set(themeDetails);
      }

      const favDetails = localStorage.getItem("cgfav");
      if (favDetails) {
        favList.set(JSON.parse(favDetails));
      }

      var walletDetails = localStorage.getItem("cgwallet");
      if (walletDetails) {
        walletDetails = JSON.parse(walletDetails);
        if (walletDetails && walletDetails.connected) {
          wallet.merge(walletDetails);
          //console.log("wallet", wallet.get());
        }
      }
    });
    //mounted.set(true);
    setTimeout(() => {
      TagManager.initialize({ gtmId: 'UA-26463863-9' });

      const script = document.createElement("script");
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8828968635890726';
      script.async = true;
      document.body.appendChild(script);

      /*       const script1 = document.createElement("script");
            script1.src = 'https://static.cloudflareinsights.com/beacon.min.js';
            script1.defer = true;
            script1.setAttribute("data-cf-beacon", '{"token": "289a7d05a22e4a18bf20ebfc2ddf744e"}');
            document.body.appendChild(script1); */

    }, router.asPath == '/' ? 10000 : 100);

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('storage', null);
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleResize() {
    widths.set(window.innerWidth);
    /*if(widths.get() < 900){
        views.bottom.set(false);
    }*/
  }

  return (
    <>
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "64ea7b50dddc40e595b5e5f15089b075"}'></script>
      <Head>
        <meta name="robots" content="index" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:image" content="https://www.cryptogems.info/sf/logo/7oRNGD.png" key="ogimage" />
        <meta property="og:site_name" content="CryptoGems" key="ogsitename" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@CryptoGemsInfo" />
        <meta name="twitter:image" content="https://www.cryptogems.info/sf/logo/7oRNGD.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "ResearchProject",
              "name": "CryptoGems DEX Tracker and Tools",
              "url": "https://www.cryptogems.info/",
              "logo": "https://www.cryptogems.info/sf/logo/5uyatk.png",
              "image": "https://www.cryptogems.info/sf/logo/7oRNGD.png",
              "sameAs": [
                "https://www.facebook.com/www.cryptogems.info",
                "https://twitter.com/CryptoGemsInfo"
              ]
            })
          }}
        />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ height: "100vh", width: "100vw" }}>
          <Component {...pageProps} />
          {/* <Analytics /> */}
        </Box>
      </ThemeProvider>
    </>
  )
}
